var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-container" },
    [
      _c("HeaderBack", {
        attrs: { title: "设置祝福" },
        on: {
          back: function ($event) {
            return _vm.$emit("back")
          },
        },
      }),
      _c(
        "a-tabs",
        {
          attrs: { "default-active-key": _vm.careMethod },
          on: { change: _vm.callback },
        },
        [
          _c("a-tab-pane", { key: "h5", attrs: { tab: "H5海报" } }),
          _c("a-tab-pane", { key: "workbench", attrs: { tab: "工作台海报" } }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "poster" },
        [
          _c("div", { staticClass: "posters" }, [
            _c("div", { staticClass: "posters-top first" }, [
              _c("div", { staticClass: "posters-top-head" }, [
                _c("span", [_vm._v("海报")]),
                _c(
                  "svg",
                  {
                    staticClass: "iconpark-icon",
                    on: { click: _vm.addPoster },
                  },
                  [_c("use", { attrs: { href: "#piliangdaoru" } })]
                ),
              ]),
              _vm.imgList && _vm.imgList.length > 0
                ? _c(
                    "div",
                    { staticClass: "posters-top-content" },
                    _vm._l(_vm.imgList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "img",
                          style: {
                            backgroundImage:
                              "" +
                              _vm.$options.filters.posterList(
                                item,
                                _vm.basePath
                              ),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.imgChange(item, index)
                            },
                          },
                        },
                        [
                          _vm.posterId === item.carePosterId &&
                          _vm.imgList.length > 0
                            ? _c("div", { staticClass: "isSelected" }, [
                                _c("span", [_vm._v("已选中")]),
                              ])
                            : _vm._e(),
                          item.userDefined
                            ? _c("div", { staticClass: "deletemask" }, [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "iconpark-icon Circle-Button ",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delPoster(
                                          item.carePosterId,
                                          index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("use", {
                                      attrs: { href: "#shanchu-baise" },
                                    }),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.showImg
                ? _c(
                    "div",
                    { staticClass: "posters-top-content" },
                    [_c("CbNoData", { attrs: { text: "暂无海报" } })],
                    1
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "posters-top second" }, [
              _c("div", { staticClass: "posters-top-head" }, [
                _c("span", [_vm._v("文案")]),
                _c(
                  "svg",
                  { staticClass: "iconpark-icon", on: { click: _vm.onClick } },
                  [_c("use", { attrs: { href: "#piliangdaoru" } })]
                ),
              ]),
              _vm.infoList && _vm.infoList.length > 0
                ? _c(
                    "div",
                    { staticClass: "posters-top-content textPoster" },
                    _vm._l(_vm.infoList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          class: _vm.Method ? "text" : "workStation",
                          on: {
                            click: function ($event) {
                              return _vm.textChange(item, index)
                            },
                          },
                        },
                        [
                          item.userDefined
                            ? _c("div", { staticClass: "deletemask" }, [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "iconpark-icon Circle-Button ",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delBless(
                                          item.careBlessId,
                                          index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("use", {
                                      attrs: { href: "#delete-one" },
                                    }),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              class:
                                _vm.blessId === item.careBlessId
                                  ? "textColor"
                                  : "textItems",
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.Method
                                      ? item.careBlessTextOne + " #姓名#"
                                      : item.careBlessTextOne
                                  )
                                ),
                              ]),
                              _c("p", { staticClass: "blessTwo" }, [
                                _vm._v(_vm._s(item.careBlessTextTwo)),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.showText
                ? _c(
                    "div",
                    { staticClass: "posters-top-content" },
                    [_c("CbNoData", { attrs: { text: "暂无文案" } })],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "preview" }, [
            _c("div", { staticClass: "preview-head" }, [_vm._v("模板预览")]),
            _c("div", { staticClass: "preview-content" }, [
              _c(
                "div",
                { staticClass: "CbColorPicker" },
                [
                  _c("span", [_vm._v("字体颜色")]),
                  _c("colorPicker", {
                    on: { change: _vm.headleChangeColor },
                    model: {
                      value: _vm.color,
                      callback: function ($$v) {
                        _vm.color = $$v
                      },
                      expression: "color",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "proview-min" }, [
                _vm._m(0),
                _c("div", { staticClass: "view" }, [
                  _c("div", {
                    staticClass: "title",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.Method
                          ? _vm.type
                            ? "生日祝福"
                            : "周年祝福"
                          : "工作台"
                      ),
                    },
                  }),
                  _vm.Method
                    ? _c(
                        "div",
                        {
                          staticClass: "content",
                          style: { color: "" + _vm.color },
                          attrs: { id: "content" },
                        },
                        [
                          _vm.posterId
                            ? _c("img", {
                                staticClass: "img-poster",
                                attrs: {
                                  src:
                                    "" +
                                    _vm.$options.filters.posters(
                                      _vm.imgSrc,
                                      _vm.basePath
                                    ),
                                },
                              })
                            : _vm._e(),
                          !_vm.posterId && !_vm.blessId
                            ? _c("p", { staticClass: "preview-p" }, [
                                _vm._v("请选择左侧海报及文案预览"),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "h5-text" }, [
                            _vm.message.messageOne && _vm.message.messageTwo
                              ? _c("div", { staticClass: "greetings-name" }, [
                                  _vm._v("#姓名#："),
                                ])
                              : _vm._e(),
                            _c("p", { staticClass: "greetings-title" }, [
                              _vm._v(_vm._s(_vm.message.messageOne)),
                            ]),
                            _c("p", { staticClass: "greetings-title" }, [
                              _vm._v(" " + _vm._s(_vm.message.messageTwo)),
                            ]),
                          ]),
                        ]
                      )
                    : _c(
                        "div",
                        { staticClass: "content", attrs: { id: "content" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "banner",
                              style: {
                                color: "" + _vm.color,
                                backgroundImage:
                                  "url(" +
                                  _vm.$options.filters.posters(
                                    _vm.imgSrc,
                                    _vm.basePath
                                  ) +
                                  ")",
                              },
                            },
                            [
                              _vm.message.messageOne || _vm.message.messageTwo
                                ? _c("p", { staticClass: "daytitle bold" }, [
                                    _vm._v("今天~"),
                                  ])
                                : _vm._e(),
                              _c("p", { staticClass: "text firstText" }, [
                                _vm._v(_vm._s(_vm.message.messageOne)),
                              ]),
                              _c("p", { staticClass: "text" }, [
                                _vm._v(_vm._s(_vm.message.messageTwo)),
                              ]),
                            ]
                          ),
                          _vm.posterId
                            ? _c("div", { staticClass: "work-content" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/culture/station.png"),
                                    alt: "",
                                  },
                                }),
                              ])
                            : _vm._e(),
                          !_vm.posterId && !_vm.blessId
                            ? _c("p", { staticClass: "preview-p" }, [
                                _vm._v("请选择左侧海报及文案预览"),
                              ])
                            : _vm._e(),
                        ]
                      ),
                ]),
              ]),
            ]),
          ]),
          _c(
            "Modal",
            {
              attrs: {
                closable: "",
                size: "normal",
                "z-index": 2022,
                visible: _vm.visible,
                title: "文案自定义",
              },
              on: {
                cancel: function ($event) {
                  return _vm.onClose("visible")
                },
                ok: function ($event) {
                  return _vm.onSubmit("visible")
                },
              },
            },
            [
              !_vm.Method
                ? _c(
                    "a-form-model",
                    {
                      ref: "ruleForm",
                      attrs: {
                        model: _vm.form,
                        rules: _vm.rules,
                        "label-col": _vm.labelCol,
                        "wrapper-col": _vm.wrapperCol,
                      },
                    },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: "第一段", prop: "careBlessTextOne" },
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              "max-length": 12,
                              placeholder: "请输入第一段祝福语",
                            },
                            model: {
                              value: _vm.form.careBlessTextOne,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "careBlessTextOne", $$v)
                              },
                              expression: "form.careBlessTextOne",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: "第二段", prop: "careBlessTextTwo" },
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              "max-length": 12,
                              placeholder: "请输入第二段祝福语",
                            },
                            model: {
                              value: _vm.form.careBlessTextTwo,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "careBlessTextTwo", $$v)
                              },
                              expression: "form.careBlessTextTwo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "alerted" }, [
                        _c("p", [_vm._v("说明: ")]),
                        _c("p", [
                          _vm._v(
                            "工作台海报目前支持两段文字，每段最多12个字符。编辑自定义的要注意字体格式"
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _c(
                    "a-form-model",
                    {
                      ref: "ruleForm",
                      attrs: {
                        model: _vm.form,
                        rules: _vm.rulesStation,
                        "label-col": _vm.labelCol,
                        "wrapper-col": _vm.wrapperCol,
                      },
                    },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "标题", prop: "careBlessTextOne" } },
                        [
                          _c("a-input", {
                            attrs: {
                              "max-length": 6,
                              placeholder: "请输入1-6个字符",
                            },
                            model: {
                              value: _vm.form.careBlessTextOne,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "careBlessTextOne", $$v)
                              },
                              expression: "form.careBlessTextOne",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "内容", prop: "careBlessTextTwo" } },
                        [
                          _c("a-textarea", {
                            attrs: {
                              placeholder: "请输入1-100个字符",
                              "auto-size": { minRows: 3, maxRows: 5 },
                              "max-length": 100,
                            },
                            model: {
                              value: _vm.form.careBlessTextTwo,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "careBlessTextTwo", $$v)
                              },
                              expression: "form.careBlessTextTwo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "alerted" }, [
                        _c("p", [_vm._v("说明: ")]),
                        _c("p", [
                          _vm._v(
                            "H5海报目前支持标题和内容的编辑，标题最多6个字符，内容最多100个字符"
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c(
            "Modal",
            {
              attrs: {
                closable: "",
                "overflow-auto": "",
                visible: _vm.addImgeVisible,
                title: "添加海报",
                size: "large",
                "z-index": 2022,
              },
              on: {
                cancel: function ($event) {
                  return _vm.onClose("addImgeVisible")
                },
                ok: function ($event) {
                  return _vm.onSubmit("addImgeVisible")
                },
              },
            },
            [
              _c(
                "div",
                {
                  style: {
                    width: _vm.Method ? "750px" : "694px",
                    margin: "0 auto",
                  },
                },
                [
                  _c("CBCropper", {
                    ref: "CBCropper",
                    attrs: {
                      "img-size": _vm.Method
                        ? { width: 750, height: 1624 }
                        : { width: 694, height: 280 },
                      "file-size": 20,
                      "type-list": ["jpg", "jpeg", "png"],
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "complete" },
        [
          _c(
            "a-button",
            {
              staticClass: "setting",
              attrs: { type: "primary" },
              on: { click: _vm.Setting },
            },
            [_vm._v("完成设置")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "receiver" }, [
      _c("div", { staticClass: "long-view" }),
      _c("div", { staticClass: "short-view" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }